<template>
    <div class="trade">
        <van-sticky :z-index="999" :offset-top="0.1">
            <van-nav-bar class="tradetitle" :title="$t('Mt4ApiMenu.trade')" @click-right="onClickRight" :style="{backgroundColor: formatColor(positionProfit) }">
                <template #right>
                    <van-icon name="plus" size="18" color="#fff" />
                </template>
                <template #left>
                    <div><span v-if="positionProfit!=0" >{{formatPrice(positionProfit,2)}}</span>&nbsp;{{accountInfo.currency}}</div>

                </template>
            </van-nav-bar>
        </van-sticky>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <div class="trade-content">
                <div class="trade-radius">
                    <van-collapse v-model="activeTopNames">
                        <!--<van-cell value="账户信息" style="font-weight: 700" />-->
                        <van-collapse-item>
                            <template #title>
                                <div>{{ $t("AccountInfo.balance") }}</div>
                                <div>{{ $t("AccountInfo.credit") }}</div>
                                <div>{{ $t("AccountInfo.equity") }}</div>
                                <div v-if="accountInfo.margin>0">{{ $t("Mt4ApiTrade.margin") }}</div>
                                <div>{{ $t("Mt4ApiTrade.marginFree") }}</div>
                                <div v-if="accountInfo.margin > 0">
                                    {{ $t("Mt4ApiTrade.marginLevel") }}
                                </div>
                            </template>
                            <template #right-icon>
                                <div>
                                    <div style="text-align: right">
                                        {{ formatPrice(accountInfo.balance, 2) }}
                                    </div>
                                    <div style="text-align: right">
                                        {{ formatPrice(accountInfo.credit, 2) }}
                                    </div>
                                    <div style="text-align: right">
                                        {{ formatPrice(accountInfo.equity, 2) }}
                                    </div>
                                    <div v-if="accountInfo.margin > 0" style="text-align: right">
                                        {{ formatPrice(accountInfo.margin, 2) }}
                                    </div>
                                    <div style="text-align: right">
                                        {{ formatPrice(accountInfo.marginFree, 2) }}
                                    </div>
                                    <div v-if="accountInfo.margin > 0"
                                         style="text-align: right">
                                        {{ formatPrice(accountInfo.marginLevel, 2) }}%
                                    </div>
                                </div>
                            </template>
                        </van-collapse-item>
                    </van-collapse>
                </div>
                <van-cell :value="$t('Mt4ApiTrade.positionOrder')" v-if="records.length > 0" />
                <div class="trade-radius">
                    <van-collapse v-model="activeNames" accordion>
                        <van-swipe-cell v-for="item in records" :key="item.ticket">
                            <template #default>
                                <div @touchstart="gtouchstart(item)"
                                     @touchmove="gtouchmove()"
                                     @touchend="gtouchend()">
                                    <van-collapse-item>
                                        <template #title>
                                            <div style="font-weight: bold">
                                                {{ item.symbol }},&nbsp;<span :style="{ color: formatColor(item.type) }">
                                                    {{ formattype(item.type) }}&nbsp;&nbsp;&nbsp;{{formatVolume(item.volume)}}
                                                </span>
                                            </div>
                                            <div>
                                                {{ formatPrice(item.open_price, item.digits) }} &nbsp;
                                                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                                                &nbsp;{{ formatPrice(item.close_price, item.digits) }}
                                            </div>
                                        </template>
                                        <template #right-icon>
                                            <div>
                                                <div style="color: darkgray">
                                                    {{item.open_time.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")}}
                                                </div>
                                                <div style="float: right">
                                                    <span style="font-weight: 700"
                                                          :style="{color: formatColor(item.profit) }">{{ formatPrice(item.profit, 2) }}</span>
                                                </div>
                                            </div>
                                        </template>
                                        <van-row>
                                            <van-col span="12">
                                                S/L:<span class="itemright">{{ formatSlTp(item.sl, item.digits) }}</span>
                                            </van-col>
                                            <van-col span="12">
                                                {{ $t("Report.swaps") }}:<span class="itemright">
                                                    {{ formatPrice(item.storage, 2) }}
                                                </span>
                                            </van-col>
                                            <van-col span="12">
                                                T/P:<span class="itemright">{{ formatSlTp(item.tp, item.digits) }}</span>
                                            </van-col>
                                            <van-col span="12">
                                                {{ $t("Report.taxes") }}:<span class="itemright">
                                                    {{ formatPrice(item.taxes, 2) }}
                                                </span>
                                            </van-col>
                                            <van-col span="12">
                                                ID:<span class="itemright">
                                                    {{ item.ticket }}
                                                </span>
                                            </van-col>
                                            <van-col span="12">
                                                {{ $t("Report.charges") }}:<span class="itemright">
                                                    {{ formatPrice(item.commission, 2) }}
                                                </span>
                                            </van-col>
                                        </van-row>
                                    </van-collapse-item>
                                </div>
                            </template>
                            <template #right>
                                <van-button square
                                            icon="passed"
                                            :type="item.profit >= 0 ? 'info' : 'danger'"
                                            @click="orderClose(item)" />
                                <van-button square
                                            icon="edit"
                                            :type="item.profit >= 0 ? 'info' : 'danger'"
                                            @click="orderEdit(item)" />
                                <van-button square
                                            icon="plus"
                                            :type="item.profit >= 0 ? 'info' : 'danger'"
                                            @click="orderNew(item)" />
                                <van-button square
                                            icon="chart-trending-o"
                                            :type="item.profit >= 0 ? 'info' : 'danger'"
                                            @click="toChart(item)" />
                            </template>
                        </van-swipe-cell>
                    </van-collapse>
                </div>
                <van-cell class="orders" :value="$t('Mt4ApiTrade.pendingOrder')" v-if="recordsPending.length > 0" />
                <div class="trade-radius">
                    <van-collapse v-model="activePendingNames" accordion>
                        <van-swipe-cell v-for="item in recordsPending" :key="item.ticket">
                            <template #default>
                                <div @touchstart="gtouchstart(item)"
                                     @touchmove="gtouchmove()"
                                     @touchend="gtouchend()">
                                    <van-collapse-item>
                                        <template #title>
                                            <div style="font-weight: bold">
                                                {{ item.symbol }},&nbsp;<span :style="{ color: formatColor(item.type) }">{{ formattype(item.type) }}</span>
                                            </div>
                                            <div>
                                                {{ formatVolume(item.volume) }} at
                                                {{ formatPrice(item.open_price, item.digits) }} &nbsp;
                                            </div>
                                        </template>
                                        <template #right-icon>
                                            <div>
                                                <div style="color: darkgray">
                                                    {{item.open_time.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")}}
                                                </div>
                                                <div style="float:right;"><span>{{ formatPrice(item.close_price, item.digits) }}</span></div>
                                            </div>
                                        </template>

                                        <van-row>
                                            <van-col span="12">
                                                S/L:<span class="itemright">{{ formatSlTp(item.sl, item.digits) }}</span>
                                            </van-col>
                                            <van-col span="12">
                                                {{ $t("Report.swaps") }}:<span class="itemright">
                                                    {{ formatPrice(item.storage, 2) }}
                                                </span>
                                            </van-col>
                                            <van-col span="12">
                                                T/P:<span class="itemright">{{ formatSlTp(item.tp, item.digits) }}</span>
                                            </van-col>
                                            <van-col span="12">
                                                {{ $t("Report.taxes") }}:<span class="itemright">
                                                    {{ formatPrice(item.taxes, 2) }}
                                                </span>
                                            </van-col>
                                            <van-col span="12">
                                                ID:<span class="itemright">
                                                    {{ item.ticket }}
                                                </span>
                                            </van-col>
                                            <van-col span="12">
                                                {{ $t("Report.charges") }}:<span class="itemright">
                                                    {{ formatPrice(item.commission, 2) }}
                                                </span>
                                            </van-col>
                                        </van-row>
                                    </van-collapse-item>
                                </div>
                            </template>
                            <template #right>
                                <van-button square
                                            icon="passed"
                                            type="info"
                                            @click="orderClose(item)" />
                                <van-button square
                                            icon="edit"
                                            type="info"
                                            @click="orderEdit(item)" />
                                <van-button square
                                            icon="plus"
                                            type="info"
                                            @click="orderNew(item)" />
                                <van-button square
                                            icon="chart-trending-o"
                                            type="info"
                                            @click="toChart(item)" />
                            </template>
                        </van-swipe-cell>
                    </van-collapse>
                </div>
            </div>
        </van-pull-refresh>
        <van-action-sheet v-model="show"
                          :actions="actions"
                          :cancel-text="$t('cancel')"
                          close-on-click-action
                          @cancel="onCancel"
                          @select="onSelect"
                          @closed="onClosed"
                          :description="description" />
        <van-action-sheet v-model="showPending"
                          :actions="actionsPending"
                          :cancel-text="$t('cancel')"
                          close-on-click-action
                          @cancel="onCancel"
                          @select="onSelect"
                          @closed="onClosed"
                          :description="description" />
    </div>
</template>
<script>
    import { GetPositions, GetAccountInfo, DeleteOrder, LoadSymbols, GetLastPrice } from "@/api/mt4Api";
    import { formatType, forPrice } from "@/utils/commons";
    import { sendWebsocket, closeWebsocket } from '@/utils/websocket'
    import { Notify, Dialog } from 'vant';
    export default {
        data() {
            return {
                activeTopNames: [],
                activePendingNames: [],
                activeNames: [],
                pageIndex: 1,
                pageSize: 20,
                records: [],
                recordsPending: [],
                total: 0,
                accountInfo: {
                    balance: "",
                    credit: "",
                    equity: "",
                    margin: "",
                    marginFree: "",
                    profit: "",
                    marginLevel: "",
                    currency: '',
                },
                isLoading: false,
                timeOutEvent: 0,
                touchItem: null,
                description: "",
                show: false,
                actions: [
                    { name: this.$t('Mt4ApiTrade.closeOrder'), color: "", className: "actionSheet" },
                    { name: this.$t('Mt4ApiTrade.modifyOrder'), className: "actionSheet" },
                    { name: this.$t('Mt4ApiTrade.trade'), className: "actionSheet" },
                    { name: this.$t('Mt4ApiMenu.chart'), className: "actionSheet" },
                ],
                showPending: false,
                actionsPending: [
                    { name: this.$t('Mt4ApiTrade.deleteOrder'), color: "", className: "actionSheet" },
                    { name: this.$t('Mt4ApiTrade.modifyOrder'), className: "actionSheet" },
                    { name: this.$t('Mt4ApiTrade.trade'), className: "actionSheet" },
                    { name: this.$t('Mt4ApiMenu.chart'), className: "actionSheet" },
                ],
                positionProfit: 0,
                userinfo: this.$store.state.mt4userinfo,
                symmbolInfo: [],
                lastPriceInfo: [],
            };
        },
        computed: {},
        mounted() {
            this.$store.commit("toggleTabbarShow", true);
            this.$store.commit("toggleTabbarCurrentShow", "Mt4");
            this.requestWs();
        },
        created() {
            this.lastPrice();
            this.loadData();
            this.loadSymbols();
            this.loadAccountInfo();
        },
        beforeDestroy() { closeWebsocket(); },
        beforeRouteLeave(to, from, next) {
            closeWebsocket();
            next()
        },
        methods: {
            onClickLeft() {
                //Toast('返回');
            },
            lastPrice() {
                GetLastPrice().then((res) => {
                    if (res.code === 200) {
                        this.lastPriceInfo.push(...res.msg.AllSymbol);
                    }
                    else {
                        console.log(res.msg);
                    }
                });
            },
            loadSymbols() {
                let param = {};
                LoadSymbols(param).then((res) => {
                    if (res.code = 200) {
                        this.symmbolInfo.push(...res.msg);
                        //console.log(res.msg);
                    } else {
                        Notify({ type: 'danger', message: this.$t(res.msg) });
                    }
                });
            },
            onClickRight() {
                this.$router.push({
                    path: "/neworder",
                    query: {
                        symbol: "XAUUSD",
                        orderType: 0,
                        digits: 2,
                    },
                });
            },
            loadData() {
                this.positionProfit = 0;
                let param = {
                    //offset: this.pageIndex,
                    //pageSize: 12,
                    dateTime: this.searchDate,
                    /* search: this.searchText*/
                };
                GetPositions(param).then((res) => {
                    this.isLoading = false;
                    if ((res.code = 200)) {
                        this.records = [];
                        this.recordsPending = [];
                        this.total = res.msg.total;
                        if (this.total > 0) {
                            if (this.pageIndex == 1) {
                                res.msg.rows.forEach((element) => {
                                    if (element.type <= 1) {
                                        this.records.push(element);
                                        var temp = element.profit + element.commission + element.taxes + element.storage
                                        this.positionProfit += temp;
                                    } else {
                                        this.recordsPending.push(element);
                                    }
                                });
                            } else {
                                res.msg.rows.forEach((element) => {
                                    if (element.type <= 1) {
                                        this.records.push(element);
                                    } else {
                                        this.recordsPending.push(element);
                                    }
                                });
                            }
                        }

                    } else {
                        Notify({ type: 'danger', message: this.$t(res.msg) });
                    }
                });
                GetAccountInfo(param).then((res) => {
                    if ((res.code = 200)) {
                        localStorage.setItem('accountinfo', JSON.stringify(res.msg));
                        this.loadFromStorage(res.msg);
                    } else {
                        Notify({ type: 'danger', message: this.$t(res.msg) });
                    }
                });
            },
            loadAccountInfo() {
                let param = {
                    xxx: "",
                };
                let info = localStorage.getItem('accountinfo');
                if (info == null) {
                    GetAccountInfo(param).then((res) => {
                        if ((res.code = 200)) {
                            this.loadFromStorage(res.msg);
                        } else {
                            Notify({ type: 'danger', message: this.$t(res.msg) });
                        }
                    });
                } else {
                    this.loadFromStorage(JSON.parse(info));
                }
            },
            loadFromStorage(json) {
                this.accountInfo.balance = json.balance + "";
                this.accountInfo.credit = json.credit;
                this.accountInfo.equity = json.equity;
                this.accountInfo.margin = json.margin;
                this.accountInfo.marginFree = json.marginfree;
                this.accountInfo.profit = json.profit;
                if (json.margin <= 0) {
                    this.accountInfo.marginLevel = 0;
                } else {
                    this.accountInfo.marginLevel =
                        (json.equity / json.margin) * 100;
                }
                this.accountInfo.company = json.company;
                this.accountInfo.name = json.name;
                this.accountInfo.currency = json.currency;
            },
            formattype(value) {
                return formatType(value);
            },
            formatVolume(value) {
                return (value * 0.01).toFixed(2);
            },
            formatColor(value) {
                if (value >= 0) {
                    return "#13b5b1";
                }
                return "#ED7F35";
            },
            formatPrice(value, len) {
                return forPrice(value, len);
            },
            formatSlTp(value,digits) {
                if (value <= 0) {
                    return "-";
                }
                return this.formatPrice(value, digits);
            },
            onRefresh() {
                this.loadData();
                this.loadAccountInfo();
            },
            orderClose(item) {
                console.log(item);
                if (item.type > 1) {
                    let param = {
                        volume: item.volume,
                        sl: item.sl,
                        tp: item.tp,
                        cmd: item.type,
                        symbol: item.symbol,
                        ticket: item.ticket,
                    };
                    let confMsg =
                        this.$t("Mt4ApiTrade.ticket") +
                        ":#" +
                        item.ticket +
                        " " +
                        item.symbol +
                        "," +
                        this.formattype(item.type) +
                        " " +
                        this.formatVolume(item.volume) +
                        " at " +
                        this.formatPrice(item.open_price, item.digits);
                    this.$dialog
                        .confirm({
                            message: confMsg,
                            confirmButtonText: this.$t("Mt4ApiTrade.delete"),
                        })
                        .then(() => {
                            DeleteOrder(param).then((res) => {
                                if (res.code == 200) {
                                    Notify({ type: 'success', message: this.$t(res.msg) });
                                    this.loadData();
                                } else {
                                    Dialog.alert({
                                        message: this.$t(res.msg),
                                    }).then(() => {
                                        // on close
                                    });
                                }
                            });
                        })
                        .catch(() => {
                            console.log("cancel");
                        });
                } else {
                    this.$router.push({
                        path: "/neworder",
                        query: {
                            ticket: item.ticket,
                            symbol: item.symbol,
                            orderType: 1,
                            sl: item.sl,
                            tp: item.tp,
                            digits: item.digits,
                            volume: item.volume,
                            cmd: 1,
                        },
                    });
                }
            },
            orderEdit(item) {
                let type = parseInt(item.type) <= 1 ? 1 : item.type;
                this.$router.push({
                    path: type==1 ? "/neworder" : "pendingorder" ,
                    query: {
                        ticket: item.ticket,
                        symbol: item.symbol,
                        orderType: 2,
                        price: item.open_price,
                        sl: item.sl,
                        tp: item.tp,
                        digits: item.digits,
                        volume: item.volume,
                        cmd: type,
                    },
                });
            },
            orderNew(item) {
                this.$router.push({
                    path: "/neworder",
                    query: {
                        symbol: item.symbol,
                        orderType: 0,
                        digits: item.digits,
                        cmd: 1,
                    },
                });
            },
            toChart(item) {
                this.$router.push({
                    path: "/chart",
                    query: {
                        symbol: item.symbol,
                        digits: item.digits,
                    },
                });
            },
            gtouchstart(item) {
                this.timeOutEvent = setTimeout(() => {
                    this.timeOutEvent = 0;
                    //                    console.log(item);
                    this.touchItem = item;

                    if (item.type <= 1) {
                        this.description =
                            this.$t("Mt4ApiTrade.ticket") +
                            ":#" +
                            item.ticket +
                            " " +
                            item.symbol +
                            "," +
                            this.formattype(item.type) +
                            " " +
                            this.formatVolume(item.volume);
                        this.show = true;
                    } else {
                        this.description =
                            this.$t("Mt4ApiTrade.ticket") +
                            ":#" +
                            item.ticket +
                            " " +
                            item.symbol +
                            "," +
                            this.formattype(item.type) +
                            " " +
                            this.formatVolume(item.volume) +
                            " at " +
                            this.formatPrice(item.open_price, item.digits);
                        this.showPending = true;
                    }
                }, 600); //这里设置定时器，定义长按500毫秒触发长按事件，时间可以自己改，个人感觉500毫秒非常合适
                return false;
            },
            gtouchmove() {
                clearTimeout(this.timeOutEvent);
                this.timeOutEvent = 0;
                console.log("cancel touch");
            },
            gtouchend() {
                clearTimeout(this.timeOutEvent);
                if (this.timeOutEvent !== 0) {
                    console.log("click,not touch");
                }
                return false;
            },
            onCancel() { },
            onClosed() { },
            onSelect(item, index) {
                this.show = false;
                this.showPending = false;
                console.log(`onSelect:${index}`)
                if (index == 0) {
                    this.orderClose(this.touchItem);
                } else if (index == 1) {
                    this.orderEdit(this.touchItem);
                } else if (index == 2) {
                    this.orderNew(this.touchItem);
                } else if (index == 3) {
                    this.toChart(this.touchItem);
                }
            },
            requestWs() {
                closeWebsocket();
                var clientid = this.userinfo.clientid;
                sendWebsocket(clientid, this.wsMessage, this.wsError);
            },
            wsMessage(priceData) {
                //console.log(peiceData);
                let postions = this.records;
                let pendings = this.recordsPending;
                priceData.forEach((x, priceIndex) => {
                    this.lastPriceInfo.forEach((last, lastIndex) => {
                        if (last.symbol == x.symbol) {
                            this.$set(this.lastPriceInfo, lastIndex, x);
                        }
                    })
                    postions.forEach((p, index) => {

                        if (p.symbol == x.symbol) {
                            //this.$set(this.lastPriceInfo, priceIndex, x);
                            this.symmbolInfo.forEach(s => {
                                if (s.Symbol == x.symbol) {

                                    var quoteSymbol;//计价货币
                                    var quotePrice;
                                    this.lastPriceInfo.forEach(cry => {
                                        if (cry.symbol == s.Symbol) {
                                            quoteSymbol = s.QuoteSymbol;
                                        }
                                    })
                                    //console.log(`quoteSymbol:${quoteSymbol}`)
                                    var currPrice;

                                    this.lastPriceInfo.forEach(cry => {
                                        if (cry.symbol == quoteSymbol) {
                                            currPrice = cry;
                                        }
                                    })

                                    if (quoteSymbol == "USD") {
                                        quotePrice = 1;
                                    } else if (quoteSymbol.indexOf('USD') == 0) {
                                        quotePrice = 1 / currPrice.bid;
                                    } else {
                                        quotePrice = currPrice.bid;
                                    }

                                    var currTicket = this.records[index];

                                    if (p.type == 0) {//buy bid
                                        if (p.ticket == 5784686) {
                                            // console.log(`quoteSymbol:${quoteSymbol}ContractSize:${s.ContractSize},volume:${p.volume},quotePrice:${quotePrice},bid:${currPrice.bid},open:${currTicket.open_price}`);
                                        }
                                        this.records[index].close_price = x.bid;
                                        this.records[index].profit = (x.bid - currTicket.open_price) * s.ContractSize * currTicket.volume * quotePrice * 0.01;

                                    } else { //sell ask
                                        this.records[index].close_price = x.ask;
                                        this.records[index].profit = (currTicket.open_price - x.ask) * s.ContractSize * currTicket.volume * quotePrice * 0.01;
                                    }
                                }
                            })

                        }

                    });

                    this.positionProfit = 0;
                    postions.forEach(p => {
                        var temp = p.profit + p.commission + p.taxes + p.storage
                        this.positionProfit += temp;
                    });


                    pendings.forEach((p, index) => {
                        if (p.symbol == x.symbol) {
                            if (p.type == 2 || p.type == 4) {//2 4 buy                             
                                this.recordsPending[index].close_price = x.ask;
                            } else {//3 5 sell 
                                this.recordsPending[index].close_price = x.bid;
                            }
                        }
                    });

                });


                var balance = parseFloat(this.accountInfo.balance);
                var credit = parseFloat(this.accountInfo.credit);
                //equity=balance+credit+positionProfit;
                this.accountInfo.equity = balance + credit + this.positionProfit; 
                    //可用预付款=净值-预付款；
                this.accountInfo.marginFree = this.accountInfo.equity - this.accountInfo.margin;
                    //预付款比率=净值/预付款
                if (this.accountInfo.margin>0) {
                    this.accountInfo.marginLevel = (this.accountInfo.equity / this.accountInfo.margin)*100;
                } else {
                    this.accountInfo.marginLevel = 0;
                }              

                //console.log(`equity:${this.accountInfo.equity},margin:${this.accountInfo.margin},marginLevel:${this.accountInfo.marginLevel}`);
            },
            wsError() {
                console.log("wsError");
            },

        },
    };
</script>
<style>
    .trade {
        overflow-y: auto;
        background-color: #ececec;
        height: 100vh;
        padding-bottom: 50px;
    }

    .nav-bar-arrow-size {
        font-size: 12px !important;
    }

    .itemright {
        float: right;
        padding-right: 10px;
    }

    .trade-radius .van-button {
        height: 100% !important;
    }

    .trade-content {
        background-color: #ececec;
        padding: 8px 10px;
        height: 100vh - 50px;
    }

    .trade-radius {
        border-radius: 8px;
        overflow: hidden;
    }

        .trade-radius .van-swipe-cell {
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 5px;
        }

    .trade-content > .van-cell {
        background-color: rgba(0, 0, 0, 0);
        padding: 10px 16px 1px;
    }

    .trade .van-nav-bar__title {
        color: #fff;
    }

    .trade .van-nav-bar {
        background-color: #13b5b1;
    }

    .trade-content > .van-cell:nth-child(4) {
        padding: 4px 16px 1px;
    }
</style>
<style>
    .tradetitle .van-nav-bar__left {
        color: #fff;
    }
</style>