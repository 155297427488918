import baseConfig from "@/utils/baseConfig";
import store from '@/store'

let websock = null;
let messageCallback = null;
let errorCallback = null;
let wsUrl = baseConfig.socketPath;
//let wsUrl = "ws://192.168.1.120:53886/Socket/WebSocketPrice";
let socketContent = "";
let timeout = 1000; //1 s
let timeoutObj = null;

function wsonmessage(event) {
    if (event.data instanceof Blob) {
        var reader = new FileReader();
        reader.readAsText(event.data);
        reader.onload = () => {
            socketContent = reader.result;
            var json = JSON.parse(socketContent);
            //console.log(parseInt(json.status));
            if (parseInt(json.status) === 200) {
                messageCallback(json.list);
            } else {
                if (parseInt(json.status) === 401) {
                    this.$router.push("/LoginMt4");
                } else {
                    console.log("no price");
                }
            }
        };
    } else {
        messageCallback(JSON.parse(event.data));
        console.log("Result: " + event.data);
    }

}

function websocketSend(clientid) {
    // ���ӳ���Ϊ�˾�����ws����״̬��ΪOPEN  
    var data = { clientid: clientid };
    let snedData = JSON.stringify(data);

    timeoutObj = setInterval(function () {
        //console.log("send:" + clientid);
        // ����״̬�жϣ���ΪOPENʱ��������Ϣ
        if (websock.readyState === websock.OPEN && timeout > 0) { // websock.OPEN = 1 
            // ������˵�������Ҫ�ַ�����
            websock.send(snedData);
        }
        if (websock.readyState === websock.CLOSED) { // websock.CLOSED = 3 
            console.log('websock.readyState=3');
            console.log('WebSocket State Closed');
            errorCallback();
        }
    },
        timeout);
}

function websocketclose(e) {
    console.log('WebSocket closed');
    //timeout = 0;
    //clearInterval(timeoutObj);
    // e.code === 1000  ��ʾ�����رա� ����Ϊ��Ŀ�Ķ�����, �����Ӷ��ѳɹ��������
    // e.code !== 1000  ��ʾ�������رա�
    if (e && e.code !== 1000) {
        //console.log('ws�����쳣�����Ժ�����');
        errorCallback();
        // // �����Ҫ�����쳣��������滻Ϊ����Ĵ��룬���н��в���
        // if (tryTime < 10) {
        //   setTimeout(function() {
        //    websock = null
        //    tryTime++
        //    initWebSocket()
        //    console.log(`��${tryTime}������`)
        //  }, 3 * 1000)
        //} else {
        //  Message.error('����ʧ�ܣ����Ժ�����')
        //}
    }
}

function websocketOpen(e) {
    console.log("WebSocket Start");
}

function initWebSocket() {
    if (typeof (WebSocket) === 'undefined') {
        console.log('Your browser does not support WebSocket');
        return false;
    }
    websock = new WebSocket(wsUrl);
    websock.binaryType = "blob";
    websock.onmessage = function (e) {
        wsonmessage(e);
    }
    websock.onopen = function () {
        websocketOpen();
    }
    websock.onerror = function () {
        console.log('WebSocket On Error');
        errorCallback();
    }
    websock.onclose = function (e) {
        websocketclose(e);
    }
}

export function sendWebsocket(agentData, successCallback, errCallback) {
    initWebSocket();
    messageCallback = successCallback;
    errorCallback = errCallback;
    websocketSend(agentData);
}

/**
 * �ر�websocket����
 */
export function closeWebsocket() {
    //console.log("close socket");
    clearInterval(timeoutObj);
    if (websock) {
        websock.close();
        websock.onclose();
    }


}
