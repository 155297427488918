import http from "@/utils/http";

export const sendVerifyCode = (params) => http.post('/api/SendCode/SendCode', params);


export const LogOut = (params) => http.post('/api/AuthorizeMt4/Logout', params);
export const LoginMt4 = (params) => http.post('/api/AuthorizeMt4/Authorize', params);
export const ChangeAccount = (params) => http.post('/api/AuthorizeMt4/ChangeAccount', params);



export const GetSymbols = (params = {}) => http.post('/api/Trade/GetSymbols', params);
export const GetLastPrice = (params = {}) => http.get('/api/Trade/GetLastPrice', params);
export const GetHistory = (params) => http.post('/api/Trade/GetHistory', params);
export const GetPositions = (params) => http.post('/api/Trade/GetPositions', params);
export const NewOrder = (params) => http.post('/api/Trade/NewOrder', params);
export const CloseOrder = (params) => http.post('/api/Trade/CloseOrder', params);
export const ModifyOrder = (params) => http.post('/api/Trade/ModifyOrder', params);
export const PendingOrder = (params) => http.post('/api/Trade/PendingOrder', params);
export const DeleteOrder = (params) => http.post('/api/Trade/DeleteOrder', params);
export const GetKLineData = (params) => http.post('/api/Trade/KLineData', params);
export const GetKLineDataTradingView = (params) => http.post('/api/Trade/KLineTradingView', params);

// 添加数据
// export const POST_ADDSTOCK = (params = {}) => http.post('/api/Trade/GetSymbols', params)

export const GetAccountInfo = (params) => http.get('/api/UserInfo/AccountInfo', params);
export const LoadSymbols = (params) => http.post('/api/Trade/LoadSymbols', params);





